import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { Attachment } from "@redotech/redo-model/create-conversation-body";
import { ContactFormSettings } from "@redotech/redo-model/team";
import UploadCloudIcon from "@redotech/redo-web/arbiter-icon/upload-cloud-02.svg";
import { AttachmentThumbnail } from "@redotech/redo-web/attachment-thumbnail";
import { Button, ButtonTheme, IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import XIcon from "@redotech/redo-web/icon-old/x.svg";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput, InputLines } from "@redotech/redo-web/text-input";
import {
  groupInput,
  input,
  nonEmptyValidator,
  nonWhitespaceValidator,
  phoneNumberValidator,
  validatorAll,
} from "@redotech/ui/form";
import { emailValidator } from "@redotech/web-util/form";
import * as React from "react";
import { memo, ReactNode, useRef, useState } from "react";
import { AuthenticatedShopifyExtensionClientContext } from "../../client/authenticated-client-context";
import { ShopifyExtensionClientContext } from "../../client/client-context";
import {
  getTeamInfo,
  submitContactForm,
  uploadFile,
} from "../../client/shopify-extension-client";
import * as contactFormCss from "./contact-form.module.css";

const contactFormDefaults = {
  name: "",
  email: "",
  subject: "",
  phone: "",
  comment: "",
};
function getContactFormForm(settings: ContactFormSettings) {
  return groupInput({
    ...(settings.name.show
      ? {
          name: input<string>({
            validator: validatorAll([
              ...(settings.name.required ? [nonEmptyValidator] : []),
              nonWhitespaceValidator,
            ]),
          }),
        }
      : {}),
    email: input<string>({
      validator: validatorAll([nonEmptyValidator, emailValidator]),
    }),
    ...(settings.subject.show
      ? {
          subject: input<string>({
            validator: settings.subject.required
              ? nonEmptyValidator
              : undefined,
          }),
        }
      : {}),
    ...(settings.phone.show
      ? {
          phone: input<string>({
            validator: validatorAll([
              ...(settings.phone.required ? [nonEmptyValidator] : []),
              phoneNumberValidator,
            ]),
          }),
        }
      : {}),
    comment: input<string>({
      validator: validatorAll([nonEmptyValidator, nonWhitespaceValidator]),
    }),
  });
}
const ContactFormContents = memo(function ContactFormContents({
  settings,
}: {
  settings: ContactFormSettings;
}) {
  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(false);
  const [draftAttachments, setDraftAttachments] = useState<Attachment[]>([]);
  /** Used to tell the inputs they all have focus after submitting to
   * prevent error messages after clearing after submitting. */
  const setFocusRefs = {
    name: useRef<(focus: boolean) => void>(),
    email: useRef<(focus: boolean) => void>(),
    phone: useRef<(focus: boolean) => void>(),
    comment: useRef<(focus: boolean) => void>(),
  };
  function clearForm() {
    contactFormInput.allErrors = [];
    contactFormInput.inputs.name?.setValue("");
    contactFormInput.inputs.email.setValue("");
    contactFormInput.inputs.subject?.setValue("");
    contactFormInput.inputs.phone?.setValue("");
    contactFormInput.inputs.comment.setValue("");
    setDraftAttachments([]);

    setFocusRefs.name.current?.(true);
    setFocusRefs.email.current?.(true);
    setFocusRefs.phone.current?.(true);
    setFocusRefs.comment.current?.(true);
  }
  const authenticatedShopifyExtensionClient = useRequiredContext(
    AuthenticatedShopifyExtensionClientContext,
  );

  const contactFormInput = useInput(
    getContactFormForm(settings),
    contactFormDefaults,
  );
  const [submitLoad, loadSubmit] = useTriggerLoad(async function () {
    try {
      const response = await submitContactForm(
        authenticatedShopifyExtensionClient,
        {
          formContents: {
            name: contactFormInput.inputs.name?.value,
            email: contactFormInput.inputs.email.value,
            subject: contactFormInput.inputs.subject?.value,
            phone: contactFormInput.inputs.phone?.value,
            comment: contactFormInput.inputs.comment.value,
            attachments: draftAttachments,
          },
        },
      );
      if (Math.floor(response.status / 100) === 2) {
        setSubmitted(true);
        clearForm();
      } else {
        setErrored(true);
      }
    } catch (error) {
      console.error(error);
      setErrored(true);
    }
  });

  return (
    <Flex
      className={contactFormCss.form}
      dir="column"
      gap="3xl"
      justify="center"
      p="5xl"
    >
      <Text fontFamily="text" fontSize="xl" fontWeight="semibold">
        {settings.title}
      </Text>

      {/* Name */}
      {settings.name.show && (
        <ContactFormInput
          input={
            <FormTextInput
              autocomplete="name"
              input={contactFormInput.inputs.name}
              label={undefined}
              placeholder={settings.name.placeholder}
              setFocusRef={setFocusRefs.name}
            />
          }
          label="Name"
          required={settings.name.required}
        />
      )}

      {/* Email */}
      <ContactFormInput
        input={
          <FormTextInput
            autocomplete="email"
            input={contactFormInput.inputs.email}
            label={undefined}
            placeholder={settings.email.placeholder}
            setFocusRef={setFocusRefs.email}
          />
        }
        label="Email"
        required
      />

      {/* Subject */}
      {settings.subject.show && (
        <ContactFormInput
          input={
            <FormSelectDropdown
              closeOnSelect={false}
              input={contactFormInput.inputs.subject}
              label=""
              options={settings.subject.options}
              placeholder={settings.subject.placeholder}
            >
              {(option) => option}
            </FormSelectDropdown>
          }
          label="Subject"
          required={settings.subject.required}
        />
      )}

      {/* Phone */}
      {settings.phone.show && (
        <ContactFormInput
          input={
            <FormTextInput
              autocomplete="tel"
              input={contactFormInput.inputs.phone}
              label={undefined}
              placeholder={settings.phone.placeholder}
              setFocusRef={setFocusRefs.phone}
            />
          }
          label="Phone"
          required={settings.phone.required}
        />
      )}

      {/* Comment */}
      <ContactFormInput
        input={
          <FormTextInput
            input={contactFormInput.inputs.comment}
            label={undefined}
            lines={InputLines.MULTI}
            placeholder={settings.comment.placeholder}
            setFocusRef={setFocusRefs.comment}
          />
        }
        label="Comment"
        required
      />

      {/* Attachments */}
      <ContactFormFileUpload
        draftAttachments={draftAttachments}
        setDraftAttachments={setDraftAttachments}
      />

      {(submitted || errored) && (
        <Flex
          className={
            submitted
              ? contactFormCss.successMessageContainer
              : contactFormCss.errorMessageContainer
          }
          dir="column"
          justify="center"
          p="sm"
          radius="xl"
        >
          <Text fontFamily="text" fontSize="sm" fontWeight="medium">
            {submitted
              ? settings.successMessage
              : "Error submitting form. Please try again."}
          </Text>
        </Flex>
      )}

      <Button
        className={contactFormCss.submitButton}
        disabled={contactFormInput.allErrors.length > 0}
        onClick={(_) => {
          loadSubmit();
        }}
        pending={submitLoad.pending}
        theme={ButtonTheme.PRIMARY}
      >
        Send
      </Button>
    </Flex>
  );
});

const ContactFormInput = memo(function ContactFormInput({
  label,
  required,
  input,
}: {
  label: string;
  required: boolean;
  input: ReactNode;
}) {
  return (
    <Flex dir="column" gap="sm">
      <Text fontFamily="text" fontSize="sm" fontWeight="medium" m="none">
        {label}
        {required ? "*" : ""}
      </Text>
      {input}
    </Flex>
  );
});

const ContactFormFileUpload = memo(function ContactFormFileUpload({
  draftAttachments,
  setDraftAttachments,
}: {
  draftAttachments: Attachment[];
  setDraftAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
}) {
  const shopifyExtensionClient = useRequiredContext(
    ShopifyExtensionClientContext,
  );
  const [instanceId, _] = useState(Math.random().toString(36));

  const handleUpload = async ({
    event,
    file,
  }: {
    event?: React.ChangeEvent<HTMLInputElement>;
    file?: File;
  }) => {
    const fileToUpload = file || event?.target?.files?.[0];
    if (!fileToUpload) {
      return;
    }

    const form = new FormData();
    form.append("file", fileToUpload);
    form.append("fileName", fileToUpload.name);
    const response = await uploadFile(shopifyExtensionClient, form);
    if (response.success) {
      const body = response.body;
      const newAttachment = {
        url: body.url,
        description: body.description,
        mimeType: body.mimeType,
      };
      setDraftAttachments((oldDraftAttachments) => {
        return [newAttachment, ...oldDraftAttachments];
      });
    } else {
      console.error(response.error);
    }
  };

  return (
    <>
      <Flex
        align="center"
        className={contactFormCss.fileUploadContainer}
        dir="column"
        gap="xs"
        px="3xl"
        py="xl"
        radius="xl"
        w="full"
      >
        <label htmlFor={`redo-file-upload-${instanceId}`}>
          <Flex
            align="center"
            className={contactFormCss.uploadIconContainer}
            justify="center"
            onClick={() => {
              document
                .getElementById(`redo-file-upload-${instanceId}`)
                ?.click();
            }}
            radius="md"
          >
            <UploadCloudIcon />
          </Flex>
        </label>
        <input
          className={contactFormCss.fileInput}
          id={`redo-file-upload-${instanceId}`}
          onChange={(e) => handleUpload({ event: e })}
          type="file"
        />

        <Text as="span" fontFamily="text" fontSize="sm" fontWeight="medium">
          Click to upload
        </Text>
      </Flex>
      {!!draftAttachments.length && (
        <div className={contactFormCss.fileCarousel}>
          {draftAttachments.map((attachment, index) => (
            <div
              className={contactFormCss.draftAttachmentContainer}
              key={index}
            >
              <AttachmentThumbnail
                description={attachment.description}
                mimeType={attachment.mimeType}
                showTooltip={false}
                url={attachment.url}
              />
              <div className={contactFormCss.removeFileButton}>
                <IconButton
                  onClick={() =>
                    setDraftAttachments(
                      draftAttachments.filter(
                        (draftAttachment) =>
                          draftAttachment.url !== attachment.url,
                      ),
                    )
                  }
                >
                  <XIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
});

export const ContactForm = memo(function ContactForm() {
  const authenticatedShopifyExtensionClient = useRequiredContext(
    AuthenticatedShopifyExtensionClientContext,
  );
  const settingsLoad = useLoad(async () => {
    const teamInfo = await getTeamInfo(authenticatedShopifyExtensionClient);
    return teamInfo.contactFormSettings;
  }, []);
  if (
    settingsLoad.pending ||
    !settingsLoad.value ||
    !settingsLoad.value.enabled
  ) {
    return null;
  }
  return <ContactFormContents settings={settingsLoad.value} />;
});
